import { useEffect, useMemo, useState } from 'react';

const nonHell = [
  'Interpreted to not have to wait for compile times 💪🚀',
  'Memory-safe garbage collection 🗑️',
  'Rust-like syntax 🦀',
  'JavaScript-like syntax 🤯',
  'Rust-like performance 🚀',
  'JavaScript-like performance 🤯',
  'Rust-like concurrency 🦀',
  'JavaScript-like concurrency 🤯',
  'Rust-like module system 🦀',
  'JavaScript-like module system 🤯',
  'Rust-like error handling 🦀',
  'JavaScript-like error handling 🤯',
  'Rust-like type system 🦀',
  'JavaScript-like type system 🤯',
  'Rust-like code organization 🦀',
  'JavaScript-like code organization 🤯',
  'Rust-like code structure 🦀',
  'JavaScript-like code structure 🤯',
  'Rust-like code style 🦀',
  'JavaScript-like code style 🤯',
  'Rust-like code quality 🦀',
  'JavaScript-like code quality 🤯',
  'Rust-like code safety 🦀',
  'JavaScript-like code safety 🤯',
  'Rust-like code optimization 🦀',
  'JavaScript-like code optimization 🤯',
  'Easy to learn 🤯',
  'NPM support 📦',
  'Rust-like package manager 🦀',
  'JavaScript-like package manager 🤯',
  'Type system that is both Rust-like and JavaScript-like 🤯',
  'Rust-like TypeScript support 🦀',
  '🤯'
];

const hellReal = Array(33).fill('        tibb');

const hellReal2 = [
  // write different scary glitchy messages mentioning tibb
  'he is gone',
  '       ..',
  ' ',
  ',',
  ' the     tibb',
  'tibb',
  ',',
  'ibb',
  'bi',
  't',
  'i got the',
  'hell',
  'w',
  'trp',
  '||||||||||||||||',
  'gone',
  'tibb',
  '`',
  '.',
  '/',
  '-',
  '23',
  'esc',
  '🤯',
  '<script>alert("tibb")</script>',
  ' ',
  '123',
  '0',
  '404',
  'tibb',
  ' ',
  'run     t',
  't,bb'
];

const hellReal3 = [
  '...-.',
  ',.,,',
  '.,.,',
  '",;',
  '?????',
  ':',
  '.,.-',
  '.:..',
  '.,.,',
  '.,.,',
  '.,.,',
  '########',
  '####',
  '####',
  '####',
  '34856278854365',
  '--_-_-',
  '... ..',
  '++++++++',
  '----',
  '======',
  ',,.,,',
  '//////////',
  '\\\\\\\\\\\\\\\\\\\\\\\\',
  '........',
  ',,,,,,,,',
  '@@@@@@@@',
  '!!!!!!!!',
  '????????',
  '<<<<<<<<<',
  '))))))',
  '&&&&&&&&&&',
  '********'
];

const statsMap = [
  ['100% Rust', './rust.png'],
  ['100% JavaScript', './javascript.png'],
  ['100% RustScript', <h1 style={{ color: '#ff8800' }}>RustScript</h1>],
];

const statsHell = [
  ['...._.-', './output.png'],
  ['·$%&/()', './helltibb.png'],
  ['________', <h1>🍏</h1>],
];

const statsFinal = [statsMap, statsHell];

const hellFinal = [hellReal, hellReal2, hellReal3];

const Real = ({ set, real, end }) => {
  const [hell, setHell] = useState(false);
  const [creepy, setCreepy] = useState(hellReal);
  const [stats, setStats] = useState(statsMap);
  const [listen, setListen] = useState();
  const bg = useMemo(() => new Audio('./bg.mp3'), []);
  
  useEffect(() => {
    if (hell) {
      setInterval(() => {
        setCreepy(hellFinal[Math.floor(Math.random() * hellFinal.length)]);
        setStats(statsFinal[Math.floor(Math.random() * statsFinal.length)]);
      }, 100);
    }
  }, [hell]);
  useEffect(() => {
    if (end) {
      bg.pause();
      clearTimeout(listen);
    }
  }, [end, listen, bg]);
  return (
    !end &&
    <div>
      <br/>
      <br/>
      <h1>{real ? Math.random() > 0.5 ? 'Stats' : ';;;;;;;' : 'Stats'}</h1>
      <br />
      <br />
      <div id='stats'>
        {stats.map(([stat, img], i) => (
          <div id='stats2' key={i}>
            {typeof img === 'string' ?
              <img src={img} height='75px' width='75px' alt={stat} key={i} />
              : img
            }
            <h3>{stat}</h3>
          </div>
        ))}
      </div>
      <br />
      <br />
      <h1>{real ? '' : 'Features'}</h1>
      <br />
      <br />
        {(real ? creepy : nonHell).map((text, i) => (
          <div key={i}>
            <h3>
              {text}
            </h3>
            <br />
          </div>
        ))}
        <br />
      <footer style={{ backgroundColor: real ? '#000000' : '#282c34' }}>
        <br />
        <br />
        <br />
        <br />
        <br />
        <h2 className='rust'>
            RustScript
        </h2>
        <p>by <a href="/tibb" onClick={e => {
          e.preventDefault();
          setTimeout(() => getReal(set, real, setHell, bg, setListen), 1000)
        }}>tibb</a></p>
        <br />
        <br />
        <br />
        <br />
        <br />
      </footer>
      {hell && <img src='./helltibb.png' style={{ position: 'fixed', top: '0%', left: '25%' }} height='750px' width='750px' alt="he's gone." />}
    </div>
  );
}

export default Real;

function getReal(setReal, real, setHell, bg, setListen) {
  if (real) {
    return;
  }

  const light = new Audio('./lightning.mp3');
  light.play();
  setHell(true);
  setReal(true);
  light.onended = () => {
    bg.play();
    setHell(false);
    bg.onplay = () => {
      setListen(setTimeout(() => {
        setHell(true);
        setTimeout(() => {
          setHell(false);
        }, 1300);
      }, 31500));
    }
    bg.onended = () => {
      bg.play();
    }
  }
}
