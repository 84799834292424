import { useEffect, useState } from 'react';
import Real from './Real.js';
import './App.css';

function App() {
  const [code, setCode] = useState('Made with RustScript!');
  const [real, setReal] = useState(false);
  const [desc, setDesc] = useState('');
  const [area, setArea] = useState('');
  const [end, setEnd] = useState(false);

  useEffect(() => {
    if (real) {
      setInterval(() => {
        setDesc(rustScriptDescriptions[Math.floor(Math.random() * rustScriptDescriptions.length)]);
      }, 200);
    }
  }, [real]);

  return (
    <div className={real ? 'RealApp' : 'App'}>
      {!end && <header className={real ? 'RealApp-header' : 'App-header'}>
        <h1 className='rust'>
          {real ? <a id='runscript' href="/run" onClick={e => {
          e.preventDefault();
          if (code.includes('Made') || code.includes('token')) {
            alert('Try it out. Come back when you\'re ready.');
          } else {
            setEnd(true);
            setArea('Go back to the beginning of your subconscious. Enter the room that was once no more.');
            setCode('Do you remember him?');
          }
        }}>RustScript</a> : 'RustScript'}
        </h1>
        <p>
          {real ? desc : 'is Rust with the versatity of JavaScript'}
        </p>
      </header>}
      <br />
      <br />
      {!end && <img src='./rustscript.png' alt='rustscrip'/>}
      <br/>
      <div id='try'>
        <h1>Try it out</h1>
        <br />
        <br />
        <div id='try2'>
          <textarea className='rust' rows='10' cols='50' style={{ resize: 'none', backgroundColor: '#16181e' }}
            placeholder={real ? 'prin#ln!("him")' : 'fn main() {\n  console::log!("Made with RustScript!");\n}'}
            value={area}
            readOnly={end}
            onChange={e => {
              const value = e.target.value.split(/[\n ]+/);
              setCode(real ? `Error: ${getGoneText()}` : `Error: unexpected token \`${value[value.length - 1]}\``);
              setArea(e.target.value);
            }}
          />
          {!real && <img src='./output.png' alt='output' style={{ marginLeft: '10px' }}/>}
          <textarea readOnly value={code} rows='10' cols='50' style={{ resize: 'none', backgroundColor: '#16181e', color: code.startsWith('Made') ? 'white' : 'red' }}/>
        </div>
        <p>{real ? 'Trπ ⨋om⨠th⨄⨇⨍' : 'Try something!'}</p>
      </div>
      {!code.startsWith('Made') && <Real real={real} set={setReal} end={end} />}
    </div>
  );
}

export default App;

const rustScriptDescriptions = [
  'is Rust with the versatity of JavaScript',
  'is a new language that is a mix of Rust and JavaScript',
  'is not a joke',
  'you should run',
  'close your eyes',
  'go',
  'run',
  'is not enough',
  'he won\'t be back',
  'he\'s gone',
  'he\'s gone forever',
  'just go away',
  'stop looking for him',
  'forgotten'
];

const goneText = [
  'he is gone.',
  'he is not here.',
  'it\'s behind you.',
  'not enough memory.',
  'tibb heap overflow.',
  '####################',
  '#include <tibb.h>',
  'Run away!',
  'turn around.',
  'err',
  'Recursive call',
  'not found',
  'your memory is not',
  'cpu overload',
  '🍎',
  '🧢',
  'escape',
  '----------------------',
  'self destruct',
  'this is not the page you are looking for',
  '404',
  'don\'t look',
  'look away',
  'run',
  'why are you still here?',
  'you\'re not supposed to be here',
  'quit',
  'exit',
  'stop',
  'close',
  'kill',
  'bye',
  'goodbye'
];

function getGoneText() {
  return goneText[Math.floor(Math.random() * goneText.length)];
}
